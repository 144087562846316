<template>
  <div class="background-img-3 flx flx-d-column fx-center">
    <div class="fx-center flx-d-column">
      <img src="../assets/img/icon-1-white.png" alt="Editon" />
      <h2 class="title-h2">Login</h2>
    </div>
    <div class="fx-center flx-d-column">
      <input
        class="input-style"
        placeholder="E-MAIL"
        type="text"
        name="email"
        id="email"
        v-model="form.email"
      />
      <input
        class="input-style"
        placeholder="SENHA"
        type="password"
        name="password"
        id="password"
        v-model="form.password"
      />
    </div>
    <div class="fx-center flx-d-column">
      <button class="btn-signing" type="button" @click="sendLogin">Entre</button>
      <!-- <a class="btn-recover">ops, recuperar minha senha recuperar senha</a> -->
      <p style="color:#fff"></p>
      <router-link :to="{ name: 'Register' }">
        <button class="btn-signup">Cadastre-se</button>
      </router-link>
    </div>
  </div>
</template>

<style>
</style>

<script>
import router from "../router";
import axios from "axios";
import { apiBaseUrl } from "../services/api";
import headerBuilder from "../services/headerBuilder";
import store from "../store";
import LoaderMixin from "../mixins/LoaderMixin";

export default {
  name: "Login",
  props: {
    auto_login_form: Object,
  },
  mixins: [LoaderMixin()],
  data: function () {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    if (this.auto_login_form) {
      this.form = this.auto_login_form;
      this.sendLogin();
    }
  },
  methods: {
    async sendLogin() {
      let response;
      try {
        response = await this.loaderify(
          axios.post(`${apiBaseUrl}/api/Authentication/Login`, this.form, {
            headers: headerBuilder.build(),
          })
        );
      } catch (e) {
        if (e && e.response && e.response.status == 401) {
          this.$swal({
            title: "Credenciais Incorretas",
            text: "Verifique suas credenciais e tente novamente!",
            icon: "warning",
          });
          return;
        }
        throw e;
      }
      store.commit("setToken", {
        bearer: response.data.bearerToken,
      });
      router.push({
        name: "TypeSelect",
      });
    },
  },
};
</script>
